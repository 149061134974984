import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#333F48',
    [Prop.CONTENT]: '#333F48',
    [Prop.LINK_TXT]: '#00A1DF',
    [Prop.LINK_TXT_HOVER]: '#0041AA',
    [Prop.BTN_BG]: '#00A1DF',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#0041AA',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#016A93',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#333F48',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#00A1DF',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#848484',
    [Prop.ICON]: '#00A1DF',
    [Prop.ICON_HOVER]: '#0041AA',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#00A1DF',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#0041AA',
    [Prop.LINK_TXT_HOVER]: '#B5B8BA',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#00A1DF',
    [Prop.BTN_BG_HOVER]: '#0041AA',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#016A93',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#0041AA',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#0041AA',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#0041AA',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#B5B8BA',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#0041AA',
    [Prop.LINK_TXT_HOVER]: '#00A1DF',
    [Prop.BTN_BG]: '#00A1DF',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#0041AA',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#00A1DF',
    [Prop.BTN_BG_INACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_INACTIVE]: '#00A1DF',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#0041AA',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#0041AA',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#0041AA',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#00A1DF',
    [Prop.LINK_TXT_HOVER]: '#e8f5fa',
    [Prop.BTN_BG]: '#00A1DF',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#B5B8BA',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00A1DF',
    [Prop.BTN_TXT_ACTIVE]: '#333F48',
    [Prop.BTN_BG_INACTIVE]: '#00A1DF',
    [Prop.BTN_TXT_INACTIVE]: '#333F48',
    [Prop.ELEMENT]: '#00A1DF',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#00A1DF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#e8f5fa',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#292625',
    [Prop.HEADER]: '#00A1DF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#00A1DF',
    [Prop.LINK_TXT_HOVER]: '#0041AA',
    [Prop.BTN_BG]: '#00A1DF',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#0041AA',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#0041AA',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#ffffff',
    [Prop.BTN_TXT_INACTIVE]: '#8BCCDC',
    [Prop.ELEMENT]: '#00A1DF',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#797979',
    [Prop.ICON_HOVER]: '#00A1DF',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#333F48',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#00A1DF',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#FFFFFF',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#FFFFFF',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#00A1DF',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#00A1DF',
    [Prop.CLOSE_BTN_ICON]: '#FFFFFF',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#333F48',
    [Prop.MAIN_NAV_BORDER]: '#333F48',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#333F48',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#333F48',
    [Prop.SUB_NAV_BG]: '#00A1DF',
    [Prop.SUB_NAV_TXT]: '#FFFFFF',
    [Prop.SUB_NAV_BORDER]: '#333F48',
  },
};
