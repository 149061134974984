import { Components, ColorProfiles } from '@powdr/constants';

// Color Profiles
const {
  BASE, PRIMARY, SECONDARY, TERTIARY, QUATERNARY,
} = ColorProfiles;

export const config = {
  [Components.HEADER]: BASE,
  [Components.MAIN_NAV]: BASE,
  [Components.MEGA_NAV]: BASE,
  [Components.MOBILE_NAV]: BASE,
  [Components.ECOMMERCE_BANNER]: QUATERNARY,
  [Components.WEATHER_FEED]: BASE,
  [Components.PROMOTIONS_FEED]: PRIMARY,
  [Components.PROMOTIONS]: BASE,
  [Components.ALERT_BAR]: BASE,
  [Components.STICKY_NAV]: PRIMARY,
  [Components.TERTIARY_NAV]: BASE,
  [Components.LEVELS_MODAL]: BASE,
  [Components.FOOTER]: QUATERNARY,
  [Components.PARTNERS]: QUATERNARY,
  [Components.COPYRIGHT]: PRIMARY,
  [Components.MODAL]: BASE,
  layout: {
    headerHeight: 80,
    headerHeightMobile: 60,
    headerLogoContainerWidth: 235,
    headerFeedContainer: 0,
    headerLogoHeight: 30,
  },
};
