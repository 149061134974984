import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSnowReports } from '@powdr/stores';
import { getSnowReportByLocationName } from '@powdr/utils';

export const useSnowReportMeasurements = () => {
  const dispatch = useDispatch();
  const useSnowReportData = useSelector((state) => state.snowReports);
  const { data } = useSnowReportData;
  const [snowReportData, setSnowReportData] = useState(null);

  useEffect(() => {
    dispatch(getSnowReports());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setSnowReportData(getSnowReportByLocationName(data)?.measurements || null);
    }
  }, [data]);

  return snowReportData;
};
