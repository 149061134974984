import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
  FontSize,
  FontFamily,
  TextTransformOptions,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.DOR_TOGGLE;

export const StyledDorToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  height: 100%;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED50, undefined, undefined)};
  gap: 10px;
  padding: 10px;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.BTN_TXT)};

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
    outline-offset: 0.2em;
  }

  ${Breakpoints.mobile(css`
    display: grid;
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10, undefined, undefined)};
    column-gap: 3px;
    row-gap: 0;
    width: 105px;
    padding: 5px;
  `)}
`;

const sharedDataBlockStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;

  svg {
    fill: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
    color: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
  }

  ${Breakpoints.mobile(css`
    gap: 3px;
    width: 100%;
    justify-content: center;
  `)}
`;

const sharedIconStyles = css`
  width: 25px;
  height: 25px;

  ${Breakpoints.mobile(css`
    width: 18px;
    height: 18px;
  `)}
`;

export const TemperatureInfo = styled.div`
  ${sharedDataBlockStyles}
`;

export const TempIcon = styled(Icon)`
  ${sharedIconStyles}
`;

export const SnowfallInfo = styled.div`
  ${sharedDataBlockStyles}
`;

export const SnowfallIcon = styled(Icon)`
  ${sharedIconStyles}
`;

export const SnowfallText = styled.div`
  display: flex;
  height: fit-content;
  align-items: baseline;
`;

export const SnowfallUnit = styled.span`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG20, undefined, TextTransformOptions.NO_TRANSFORM)};
  margin-left: 2px;
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${Breakpoints.mobile(css`
    grid-column: span 2;
  `)}

`;

export const IndicatorIcon = styled(Icon)`
  width: 25px;

  svg {
    fill: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
    color: ${() => componentColor(Prop.ICON, CPNT, Prop.BTN_TXT)};
  }

  ${Breakpoints.mobile(css`
    height: 10px;
    width: auto;
  `)}
`;
