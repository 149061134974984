import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components,
  AbsolutePositions,
  AlignmentShorthand,
  Breakpoints,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.COMPONENT_DRAWER;

const DrawerHeaderSize = {
  DESKTOP: 90,
  MOBILE: 75,
};

const fromLeft = {
  open: 'transform: translateX(0)',
  closed: 'transform: translateX(-100%)',
};

const fromRight = {
  open: 'transform: translateX(0)',
  closed: 'transform: translateX(100%)',
};

const fromBottom = {
  open: 'transform: translateY(0)',
  closed: 'transform: translateY(100%)',
};

const fromTop = {
  open: 'transform: translateY(0)',
  closed: 'transform: translateY(-100%)',
};

const transformMap = {
  [AbsolutePositions.TOP]: fromTop,
  [AbsolutePositions.BOTTOM]: fromBottom,
  [AbsolutePositions.LEFT]: fromLeft,
  [AbsolutePositions.RIGHT]: fromRight,
};

const getTransform = (slideInFrom) => transformMap[slideInFrom];

export const StyledComponentDrawer = styled.div`
  position: fixed;
  top: 0;
  z-index: 80; // places drawer on top of header
  overflow: hidden;
  height: 100%;
  width: 100vw;
  background-color: ${({ $componentTheme }) => componentColor(Prop.BACKGROUND, $componentTheme || CPNT)};
  transition: transform 0.5s ease-in-out;

  ${({ $isOpen, $slideInFrom }) => ($isOpen ? `
    ${getTransform($slideInFrom).open};
  ` : `
    ${getTransform($slideInFrom).closed};
  `)}
`;

export const InterceptPoint = styled.div``;

export const ComponentDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  height: ${DrawerHeaderSize.DESKTOP}px;
  background-color: ${({ $componentTheme }) => componentColor(Prop.BACKGROUND, $componentTheme || CPNT)};
  z-index: 80;
  top: 0;
  transition: box-shadow 200ms cubic-bezier(0.33, 1, 0.68, 1);

  ${Breakpoints.mobile(css`
    height: ${DrawerHeaderSize.MOBILE}px;
  `)}

  ${({ $isShowShadow }) => ($isShowShadow) && `
    box-shadow: hsla(0 0% 0% / 0.1) 0 0.5rem 1rem;
    transition-duration: 400ms;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 7.5px 0;

  .header-logo svg {
    width: 100%;
    height: 100%;
  }
`;

export const DrawerCloseButton = styled.button`
  position: fixed;
  height: fit-content;
  width: fit-content;
  top: 0;
  z-index: 1000;
  ${({ $position }) => (($position === AlignmentShorthand.TOP_RIGHT) ? 'right' : 'left')}: 0;
  padding: 25px;
  background-color: ${({ $componentTheme }) => themeUtil(Prop.BACKGROUND, $componentTheme || CPNT)};
  ${({ $isShowShadow }) => ($isShowShadow) && `
    box-shadow: hsla(0 0% 0% / 0.1) 0 0.5rem 1rem;
    transition-duration: 400ms;
  `}

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
    outline-offset: 0.2em;
  }

  .drawer-close-button svg {
    fill: ${({ $componentTheme }) => themeUtil(Prop.ICON, $componentTheme || CPNT)};
    color: ${({ $componentTheme }) => themeUtil(Prop.ICON, $componentTheme || CPNT)};
  }
`;

export const ChildrenWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  ${({ $isShowLogoHeader }) => ($isShowLogoHeader) && `padding-bottom: ${DrawerHeaderSize.DESKTOP}px;`};

  ${Breakpoints.mobile(css`
    ${({ $isShowLogoHeader }) => ($isShowLogoHeader) && `padding-bottom: ${DrawerHeaderSize.MOBILE}px;`};
  `)}
`;
