import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import {
  Components,
  DateFormats,
  HideOptions,
  Layouts,
} from '@powdr/constants';
import {
  SnowReportMeasurementDisplayNames, SnowReportUnits, SnowReportUnitsDisplay, Symbols,
} from '@powdr/constants/dor';
import { getSnowReports } from '@powdr/stores';
import { getSnowfallMeasurementUnit, slugify } from '@powdr/utils';
import { getSnowReportByLocationName } from '@powdr/utils/dor-util';

import {
  DataSection,
  ItemAlignmentWrapper,
  ItemHeader,
  ItemIcon,
  ItemText,
  ItemUnit,
  ItemValue,
  NavFeedLink,
  ReportAuthor,
  ReportAuthorImage,
  ReportAuthorName,
  ReportContentWrapper,
  ReportDataItem,
  ReportDataItems,
  ReportSection,
  StaticContent,
  StyledDorSnowReport,
  Timestamp,
} from './styles';

export const DorSnowReport = ({
  hide,
  layout,
  title,
  html,
  isParentNavFeed,
  isInColumn,
  navFeedLink,
  showIcons,
  measurementsToShow,
  reportingLocation,
  parentColorProfile,
}) => {
  const dispatch = useDispatch();
  const useSnowReportData = useSelector((state) => state.snowReports);
  const { data } = useSnowReportData;
  const measurementUnit = getSnowfallMeasurementUnit();
  const snowReport = getSnowReportByLocationName(data, reportingLocation?.value || null);

  useEffect(() => {
    dispatch(getSnowReports());
  }, [dispatch]);

  return (
    <StyledDorSnowReport
      colorProfile={parentColorProfile}
      className="dor-snow-report"
      $layout={layout || Layouts.STACKED}
      $isParentNavFeed={isParentNavFeed}
      $isInColumn={isInColumn}
    >
      <DataSection>
        {((title || html)) && (
          <StaticContent colorProfile={parentColorProfile} component={Components.DOR_SNOW_REPORT}>
            {title && (<h3>{title}</h3>)}
            {html && ReactHtmlParser(html)}
          </StaticContent>
        )}
        {(snowReport && measurementsToShow?.length > 0) && (
          <ReportDataItems
            $layout={layout || Layouts.STACKED}
            $numItems={Object.entries(snowReport.measurements)
              .filter(([k]) => measurementsToShow
                .map((filterItem) => filterItem)
                .includes(k)).length}
            $isParentNavFeed={isParentNavFeed}
          >
            {Object.entries(snowReport.measurements)
              .filter(([k]) => measurementsToShow
                .map((filterItem) => filterItem)
                .includes(k))
              .map(([k, v], idx) => (
                <ReportDataItem
                  key={slugify(k)}
                  $layout={layout || Layouts.STACKED}
                  $colorProfile={parentColorProfile}
                  $index={idx + 1}
                  $numItems={Object.entries(snowReport.measurements).length}
                  $isParentNavFeed={isParentNavFeed}
                >
                  <ItemAlignmentWrapper>
                    <ItemHeader>
                      {SnowReportMeasurementDisplayNames[k]}
                    </ItemHeader>
                    {(showIcons) && <ItemIcon $colorProfile={parentColorProfile} name="dor-snow" />}
                    {(measurementUnit !== SnowReportUnitsDisplay[SnowReportUnits.CENTIMETERS]) ? (
                      <ItemValue>
                        {`${(v === null) ? Symbols.DOUBLE_DASH : v}${measurementUnit}`}
                      </ItemValue>
                    ) : (
                      <ItemText>
                        <ItemValue>{(v === null) ? Symbols.DOUBLE_DASH : v}</ItemValue>
                        <ItemUnit>
                          {measurementUnit}
                        </ItemUnit>
                      </ItemText>
                    )}
                  </ItemAlignmentWrapper>
                </ReportDataItem>
              ))}
          </ReportDataItems>
        )}
      </DataSection>
      {(snowReport?.date || snowReport?.report) && (
        <ReportSection
          colorProfile={parentColorProfile}
          $isParentNavFeed={isParentNavFeed}
        >
          {(snowReport?.date && !hide.includes(HideOptions.DATE)) && (
            <Timestamp colorProfile={parentColorProfile}>
              {format(parseISO(snowReport.date), DateFormats.PRETTY_TIMESTAMP)}
            </Timestamp>
          )}
          {(snowReport?.report && !hide.includes(HideOptions.HTML)) && (
            <ReportContentWrapper
              $isParentNavFeed={isParentNavFeed}
              // need this to prevent issues with tab navigation
              ref={(node) => node && (isParentNavFeed
                ? node.setAttribute('inert', '')
                : node.removeAttribute('inert')
              )}
            >
              {ReactHtmlParser(snowReport.report)}
            </ReportContentWrapper>
          )}
          {(!isParentNavFeed && snowReport?.author) && (
            <ReportAuthor>
              <ReportAuthorImage src={snowReport.author.image} />
              <ReportAuthorName>{`Author: ${snowReport.author.name}`}</ReportAuthorName>
            </ReportAuthor>
          )}
          {(navFeedLink?.href) && (
            <NavFeedLink onClick={() => navFeedLink.onClick()} href={navFeedLink.href}>
              {navFeedLink.text}
            </NavFeedLink>
          )}
        </ReportSection>
      )}
    </StyledDorSnowReport>
  );
};

DorSnowReport.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string,
  isParentNavFeed: PropTypes.bool,
  isInColumn: PropTypes.bool,
  hide: PropTypes.arrayOf(PropTypes.string),
  showIcons: PropTypes.bool,
  measurementsToShow: PropTypes.arrayOf(PropTypes.shape({})),
  reportingLocation: PropTypes.shape(({
    value: PropTypes.string,
    uuid: PropTypes.string,
  })),
  navFeedLink: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorSnowReport.defaultProps = {
  layout: Layouts.STACKED,
  title: null,
  html: null,
  showIcons: false,
  isParentNavFeed: false,
  isInColumn: false,
  measurementsToShow: ['overnight', '7 days'],
  hide: [],
  parentColorProfile: null,
  navFeedLink: null,
  reportingLocation: null,
};
