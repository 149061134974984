import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, FontFamily, FontSize,
  StaticProfiles,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvGrid = styled.div`

`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
  column-gap: 50px;
  width: 100%;

  ${Breakpoints.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

export const GridCaption = styled.div`
  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined)};
  font-style: italic;
  margin-top: 15px;
`;
