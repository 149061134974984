import PropTypes from 'prop-types';
import React from 'react';

import { DorMeasurementFieldNames, MeasurementDisplayTypes } from '@powdr/constants';
import { useLiftsMeasurements } from '@powdr/hooks';

import { CircularGraph } from './circular-graph';
import {
  TextMeasurementSplit,
  TextMeasurementSplitLarge,
  TextMeasurementSplitSmall,
} from './styles';
import { TextMeasurement } from './text-measurement';

export const Lifts = ({
  measurementDisplayType,
  ignoreLiftTypes,
  parentColorProfile,
  measurementLabel,
  icon,
  season,
}) => {
  const measurementData = useLiftsMeasurements(ignoreLiftTypes, season);

  if (!measurementData) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
        <CircularGraph
          colorProfile={parentColorProfile}
          label={measurementLabel}
          openVal={measurementData[DorMeasurementFieldNames.OPEN]}
          totalVal={measurementData[DorMeasurementFieldNames.TOTAL]}
        />
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          colorProfile={parentColorProfile}
        >
          <TextMeasurementSplit className="split">
            <TextMeasurementSplitLarge className="split-large">
              {measurementData[DorMeasurementFieldNames.OPEN]}
            </TextMeasurementSplitLarge>
            <TextMeasurementSplitSmall className="split-small">
              {`/${measurementData[DorMeasurementFieldNames.TOTAL]}`}
            </TextMeasurementSplitSmall>
          </TextMeasurementSplit>
        </TextMeasurement>
      )}
    </>
  );
};

Lifts.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  ignoreLiftTypes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
  season: PropTypes.string,
};

Lifts.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  ignoreLiftTypes: null,
  icon: null,
  season: null,
};
