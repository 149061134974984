import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Checkbox, Icon } from '@powdr/components';
import { CheckedState } from '@powdr/constants';
import { capitalizeAllWords } from '@powdr/utils';

import { StyledDorFilter } from './styles';

export const DorCheckboxGroup = ({
  title,
  id,
  layout = 'row',
  className,
  initialChecked = [],
  checkInputsFields = [],
  inputLabelTitle = '',
  onFiltersChange,
  colorProfile,
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleCheckboxChange = (e) => {
    const { target } = e;
    const { value, checked: isChecked } = target;

    let updatedChecked = [];

    if (isChecked) {
      updatedChecked = [...checked, value];
    } else {
      updatedChecked = checked.filter((f) => f !== value);
    }

    setChecked(updatedChecked);
    onFiltersChange(e);
  };

  return (
    <StyledDorFilter id={id} className={`layout-${layout}`}>
      {title && <h4>{title}</h4>}
      <form className={classNames('form', className)}>
        <ul className="checkboxes">
          {checkInputsFields.map((field) => (
            <Checkbox
              id={field.id}
              name={field.id}
              label={capitalizeAllWords(field.label)}
              aria-label={`${inputLabelTitle}: ${field.id} (space to toggle checkbox)`}
              onChange={handleCheckboxChange}
              checked={(checked.includes(field.id)) ? CheckedState.CHECKED : CheckedState.UNCHECKED}
              value={field.id}
              tabIndex={0}
              colorProfile={colorProfile}
            >
              {field.icons ? (
                field.icons.map((i) => (
                  <Icon
                    key={i.icon}
                    className={`icon ${i.css}`}
                    name={i.icon}
                    width="23"
                    height="23"
                  />
                ))
              ) : (
                <Icon
                  className={`icon ${field.css}`}
                  name={field.icon}
                  width="23"
                  height="23"
                />
              )}
            </Checkbox>
          ))}
        </ul>
      </form>
    </StyledDorFilter>
  );
};

DorCheckboxGroup.propTypes = {
  title: PropTypes.string,
  inputLabelTitle: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  checkInputsFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      icon: PropTypes.string,
      css: PropTypes.string,
      label: PropTypes.string,
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          css: PropTypes.string,
          icon: PropTypes.string,
        }),
      ),
    }),
  ),
  initialChecked: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.oneOf(['row', 'column']),
  onFiltersChange: PropTypes.func,
  colorProfile: PropTypes.string,
};

DorCheckboxGroup.defaultProps = {
  title: null,
  inputLabelTitle: '',
  id: null,
  className: null,
  checkInputsFields: [],
  initialChecked: [],
  layout: 'row',
  onFiltersChange: () => { },
  colorProfile: null,
};
