import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { DorMeasurements, TvPagination } from '@powdr/components';

import { StyledTvMeasurements } from './styles';

export const TvMeasurements = ({
  className,
  displayTime,
  columnOption,
  rowOption,
  centerAlignItemContent,
  verticalAlignment,
  relationships,
}) => {
  const columns = columnOption || 2;
  const rows = rowOption || 2;
  const itemsPerPage = rows * columns;
  const numPages = Math.ceil(relationships.measurements.length / itemsPerPage);
  const perPageDisplayTime = (displayTime * 1000) / numPages;
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItems, setCurrentItems] = useState();
  const measurementRotationRef = useRef();

  useEffect(
    () => {
      measurementRotationRef.current = setTimeout(() => {
        setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1);
      }, perPageDisplayTime);

      return () => {
        clearTimeout(measurementRotationRef.current);
      };
    },
    [perPageDisplayTime, currentPage, numPages],
  );

  useEffect(() => {
    setCurrentItems(relationships.measurements.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage,
    ));
  }, [currentPage, itemsPerPage, relationships]);

  if (!currentItems) return null;

  return (
    <StyledTvMeasurements
      $centerAlignItemContent={centerAlignItemContent}
      $verticalAlignment={verticalAlignment}
    >
      {/* <button
        onClick={() => setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1)}
      >
        Move Page
      </button> */}
      <DorMeasurements
        className={`${className} tv-measurements`}
        columnOption={columns}
        relationships={{ measurements: currentItems }}
        isFromTV
      />
      <TvPagination numItems={numPages} activeItem={currentPage} />
    </StyledTvMeasurements>
  );
};

TvMeasurements.propTypes = {
  className: PropTypes.string,
  displayTime: PropTypes.number,
  columnOption: PropTypes.number,
  rowOption: PropTypes.number,
  centerAlignItemContent: PropTypes.bool,
  verticalAlignment: PropTypes.string,
  relationships: PropTypes.shape({
    measurements: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

TvMeasurements.defaultProps = {
  className: '',
  displayTime: null,
  columnOption: 2,
  rowOption: 2,
  centerAlignItemContent: false,
  verticalAlignment: null,
};
