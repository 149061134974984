import PropTypes from 'prop-types';
import React, { act } from 'react';

import { StyledTvPagination, Dot } from './styles';

export const TvPagination = ({
  numItems,
  activeItem,
}) => {
  if (numItems === 1) return null;

  return ((
    <StyledTvPagination>
      {Array
        .from({ length: numItems }, (_, index) => (index))
        .map((i) => (<Dot $active={activeItem === i} />))}
    </StyledTvPagination>
  ));
};

TvPagination.propTypes = {
  numItems: PropTypes.number,
  activeItem: PropTypes.bool,
};

TvPagination.defaultProps = {
  numItems: 0,
  activeItem: 0,
};
