import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const PartnershipsForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="partnerships-form" onSubmit={(e) => processForm(e, 'buk_CpslKTFnjBEtmdxxVev5BiZ9', null, captchaRef)}>
      <h3>First Name *</h3>
      <input className="form-control" id="firstName" name="firstName" type="text" required="" />

      <h3>Last Name *</h3>
      <input className="form-control" id="lastName" name="lastName" type="text" required="" />

      <h3>Business</h3>
      <input className="form-control" id="business" name="business" type="text" />

      <h3>Email address *</h3>
      <input className="form-control" id="email" name="email" type="email" required="" />

      <h3>Phone</h3>
      <input className="form-control" id="phone" name="phone" type="tel" />

      <h3>Message *</h3>
      <textarea id="message" rows="9" cols="33" name="message" required="" />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

PartnershipsForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

PartnershipsForm.defaultProps = {
  buttonText: null,
};
