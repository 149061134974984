import styled from 'styled-components';

import {
  ThemeProps as Prop, FontFamily, VerticalPosition, StaticProfiles,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvMeasurements = styled.div`
  width: 100%;

  .tv-measurements {
    row-gap: 125px;
    column-gap: 50px;
  }

  .tv-measurement-item {

    .text-measurement {
      ${({ $centerAlignItemContent }) => ($centerAlignItemContent) && 'align-items: center;'};
      height: 100%;
      justify-content: ${({ $verticalAlignment }) => {
    switch ($verticalAlignment) {
      case VerticalPosition.TOP: return 'flex-start';
      case VerticalPosition.MIDDLE: return 'center';
      case VerticalPosition.BOTTOM: return 'flex-end';
      default: return 'flex-start';
    }
  }};

      .label {
        ${() => getFontStyles(FontFamily.BASE_FONT, '50px')};
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
      }

      .content, .content p {
        ${() => getFontStyles(FontFamily.PRIMARY_FONT, '115px')};
      }

      .content:not(.override-color), .content:not(.override-color) p {
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
      }

      .icon {
        width: 65%;
        height: auto;
      }
    }

    .circular-graph {
      max-width: unset;
      flex-direction: column-reverse;

      .label {
        ${() => getFontStyles(FontFamily.BASE_FONT, '50px')};
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
      }

      .percent-primary-text {
        ${() => getFontStyles(FontFamily.PRIMARY_FONT, '100px')};
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
      }

      .percent-secondary-text {
        ${() => getFontStyles(FontFamily.PRIMARY_FONT, '50px')};
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
      }

      .inner-text-split-large {
        ${() => getFontStyles(FontFamily.PRIMARY_FONT, '80px')};
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
      }

      .inner-text-split-small {
        ${() => getFontStyles(FontFamily.PRIMARY_FONT, '35px')};
        color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
        margin-top: 12px;
      }
    }
  }
`;
