import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Loader } from '@powdr/components';
import {
  CheckedState, FetchStatus,
  MtBachelorMntLoc, MtBachelorMntProps,
} from '@powdr/constants';
import { getLiveWeather } from '@powdr/stores';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorLive24Table } from './styles';

export const DorLive24Table = ({
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const [colsHead, setColsHead] = useState([]);
  const [colsBody, setColsBody] = useState([]);
  const [colsFilters, setColsFilters] = useState([]);
  const [locFilters, setLocFilters] = useState([]);
  const useLiveWeatherData = useSelector((state) => state.liveWeather);
  const { status: isLoading, error, data: liveWeatherData } = useLiveWeatherData;
  const [checkedBoxs, setCheckedBoxs] = useState([]);

  useEffect(() => {
    dispatch(getLiveWeather());
  }, [dispatch]);

  useEffect(() => {
    if (liveWeatherData?.data) {
      setCheckedBoxs([
        ...Object.keys(liveWeatherData.data),
        ...Object.values(MtBachelorMntProps),
      ]);
    }
  }, [liveWeatherData?.data]);

  useEffect(() => {
    if (liveWeatherData?.liveWeatherTable) {
      const displayCleanup = {
        colsHead: [],
        colsBody: {
          columns: [],
          timestamp: {},
        },
        colsFilters: [],
        locFilters: [],
      };

      displayCleanup.colsHead = liveWeatherData.liveWeatherTable.colsHead
        .filter((f) => ![
          MtBachelorMntProps.ID,
          MtBachelorMntProps.TIMESTAMP,
        ]
          .includes(f.type))
        .filter((f) => !([
          MtBachelorMntLoc.PINE,
          MtBachelorMntLoc.SUMMIT[0],
          MtBachelorMntLoc.SUMMIT[1],
        ]
          .includes(f.id)
          && MtBachelorMntProps.RH === f.type));

      displayCleanup.colsBody = liveWeatherData.liveWeatherTable.colsBody
        .map((f) => ({
          timestamp: f.timestamp,
          columns: f.columns
            .filter((ff) => ![
              MtBachelorMntProps.ID,
              MtBachelorMntProps.TIMESTAMP,
            ]
              .includes(ff.type))
            .filter((ff) => !([
              MtBachelorMntLoc.PINE,
              MtBachelorMntLoc.SUMMIT[0],
              MtBachelorMntLoc.SUMMIT[1],
            ]
              .includes(ff.id)
              && MtBachelorMntProps.RH === ff.type)),
        }));

      displayCleanup.locFilters = liveWeatherData.liveWeatherTable.locFilters
        .filter((f) => ![MtBachelorMntLoc.CURRENT]
          .includes(f.value));

      displayCleanup.colsFilters = liveWeatherData.liveWeatherTable.colsFilters
        .filter((f) => ![
          MtBachelorMntProps.ID,
          MtBachelorMntProps.TIMESTAMP,
        ]
          .includes(f.value));

      const additionalRows = Object.entries(liveWeatherData.liveWeatherTable.additionalData)
        .map(([label, rowData]) => {
          const tempColumns = JSON.parse(
            JSON.stringify(displayCleanup.colsBody[0].columns),
          ); // need a deep copy, not a shallow copy of array

          tempColumns.forEach((col, idx) => {
            tempColumns[idx].value = '';
            rowData.forEach((location) => {
              if (col.id === location.id) {
                location.measurements.forEach((measurement) => {
                  if (measurement.type === col.type) {
                    tempColumns[idx].value = measurement.value;
                  }
                });
              }
            });
          });

          return {
            label,
            columns: tempColumns,
          };
        });

      setColsHead(displayCleanup.colsHead);
      setColsBody(additionalRows.concat(displayCleanup.colsBody));
      setColsFilters(displayCleanup.colsFilters);
      setLocFilters(displayCleanup.locFilters);
    }
  }, [liveWeatherData]);

  if (error) {
    console.error('DOR Sensors API Failed:', error.message);
    return null;
  }

  const handleCheckedProduct = useCallback((e) => {
    const { value: id, checked } = e.target;
    setCheckedBoxs((prevState) => (checked
      ? [...prevState, id]
      : prevState.filter((v) => v !== id)));
  }, []);

  return (
    <StyledDorLive24Table
      colorProfile={parentColorProfile}
      className={classNames('dorLive24Table')}
      property={property}
    >
      {(isLoading === FetchStatus.LOADING || isLoading === FetchStatus.IDLE) ? (
        <Loader
          className="loader mx-auto"
          classAnimationEffect="loader-animated flip infinite"
          size="50"
        />
      ) : (
        <>
          <h3>Filters</h3>
          <p>Locations</p>
          <div className="filters">
            {
              locFilters?.map((attr) => (
                <Checkbox
                  checked={
                    checkedBoxs.includes(attr?.value)
                      ? CheckedState.CHECKED
                      : CheckedState.UNCHECKED
                  }
                  key={attr?.value}
                  label={attr?.label}
                  name={attr?.value}
                  onChange={handleCheckedProduct}
                />
              ))
            }
          </div>
          <p>Weather Attributes</p>
          <div className="filters">
            {
              colsFilters?.map((attr) => (
                <Checkbox
                  checked={
                    checkedBoxs.includes(attr.value)
                      ? CheckedState.CHECKED
                      : CheckedState.UNCHECKED
                  }
                  key={attr.value}
                  label={attr.label}
                  name={attr.value}
                  onChange={handleCheckedProduct}
                />
              ))
            }
          </div>
          <div className="table ui-table">
            <table>
              <thead>
                <tr>
                  <th className="timestamp">
                    Date & TIME
                  </th>
                  {colsHead && colsHead
                    ?.filter((f) => checkedBoxs.includes(f.id)
                      && checkedBoxs.includes(f.type))
                    ?.map((th) => (
                      <th
                        key={`${th.id}-${th.type}`}
                        className={`${th.id} ${th.type}`}
                      >
                        {th.value}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {(colsBody) && colsBody
                  ?.map((tr) => (
                    <tr key={tr?.timestamp?.full || tr.label}>
                      <td className="timestamp">
                        {(tr.label)
                          ? tr.label
                          : (
                            <>
                              {`${tr.timestamp.parts.month.short} `}
                              {`${tr.timestamp.parts.day.num}, `}
                              {`${tr.timestamp.parts.year} `}
                              {`${tr.timestamp.parts.time.hours}:`}
                              {`${tr.timestamp.parts.time.minutes} `}
                              {`${tr.timestamp.parts.time.ampm}`}
                            </>
                          )}
                      </td>
                      {tr.columns
                        && tr.columns
                          ?.filter((f) => checkedBoxs.includes(f.id)
                            && checkedBoxs.includes(f.type))
                          .map((td) => (
                            <td key={td.id + td.type} className={td.type}>
                              {td.value}
                            </td>
                          ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </StyledDorLive24Table>
  );
};

DorLive24Table.propTypes = {
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorLive24Table.defaultProps = {
  parentColorProfile: null,
};
