// TODO: Should we just make an enum in constants for off of our redux types?
export const CONDITIONS_PENDING = 'CONDITIONS_PENDING';
export const CONDITIONS_SUCCESS = 'CONDITIONS_SUCCESS';
export const CONDITIONS_ERROR = 'CONDITIONS_ERROR';

export const DRUPAL_PENDING = 'DRUPAL_PENDING';
export const DRUPAL_SUCCESS = 'DRUPAL_SUCCESS';
export const DRUPAL_ERROR = 'DRUPAL_ERROR';

export const ECOMMERCE_RESET = 'ECOMMERCE_RESET';
export const ECOMMERCE_ERROR = 'ECOMMERCE_ERROR';

export const ECOMMERCE_CALENDAR = 'ECOMMERCE_CALENDAR';
export const ECOMMERCE_CALENDAR_PENDING = 'ECOMMERCE_CALENDAR_PENDING';

export const ECOMMERCE_PRODUCT = 'ECOMMERCE_PRODUCT';
export const ECOMMERCE_PRODUCT_RESET = 'ECOMMERCE_PRODUCT_RESET';
export const ECOMMERCE_PRODUCTS_BY_DATE = 'ECOMMERCE_PRODUCTS_BY_DATE';

export const ECOMMERCE_CART_IDLE = 'ECOMMERCE_CART_IDLE';
export const ECOMMERCE_CART_PENDING = 'ECOMMERCE_CART_PENDING';
export const ECOMMERCE_CART_SUCCESS = 'ECOMMERCE_CART_SUCCESS';
export const ECOMMERCE_CART_ERROR = 'ECOMMERCE_CART_ERROR';
export const ECOMMERCE_CART_RESET = 'ECOMMERCE_CART_RESET';
export const ECOMMERCE_CART_GET_COOKIE = 'ECOMMERCE_CART_GET_COOKIE';
export const ECOMMERCE_CART_SET_COOKIE = 'ECOMMERCE_CART_SET_COOKIE';

export const ECOMMERCE_CREATE_GUEST = 'ECOMMERCE_CREATE_GUEST';
export const ECOMMERCE_ADD_TO_CART = 'ECOMMERCE_ADD_TO_CART';

export const LOGGING_PENDING = 'LOGGING_PENDING';
export const LOGGING_SUCCESS = 'LOGGING_SUCCESS';
export const LOGGING_ERROR = 'LOGGING_ERROR';

export const NODE_PENDING = 'NODE_PENDING';
export const NODE_SUCCESS = 'NODE_SUCCESS';
export const NODE_ERROR = 'NODE_ERROR';

export const SENSORS_PENDING = 'SENSORS_PENDING';
export const SENSORS_SUCCESS = 'SENSORS_SUCCESS';
export const SENSORS_ERROR = 'SENSORS_ERROR';

export const STATUS_PENDING = 'STATUS_PENDING';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_ERROR = 'STATUS_ERROR';

export const LIFT_TRAIL_REPORT_PENDING = 'LIFT_TRAIL_REPORT_PENDING';
export const LIFT_TRAIL_REPORT_SUCCESS = 'LIFT_TRAIL_REPORT_SUCCESS';
export const LIFT_TRAIL_REPORT_ERROR = 'LIFT_TRAIL_REPORT_ERROR';

export const WEATHER_PENDING = 'WEATHER_PENDING';
export const WEATHER_SUCCESS = 'WEATHER_SUCCESS';
export const WEATHER_ERROR = 'WEATHER_ERROR';

export const VIMEO_PENDING = 'VIMEO_PENDING';
export const VIMEO_SUCCESS = 'VIMEO_SUCCESS';
export const VIMEO_ERROR = 'VIMEO_ERROR';

export const SVG_PENDING = 'SVG_PENDING';
export const SVG_SUCCESS = 'SVG_SUCCESS';
export const SVG_ERROR = 'SVG_ERROR';

export const SNOW_REPORTS_PENDING = 'SNOW_REPORTS_PENDING';
export const SNOW_REPORTS_SUCCESS = 'SNOW_REPORTS_SUCCESS';
export const SNOW_REPORTS_ERROR = 'SNOW_REPORTS_ERROR';

export const ALERTS_PENDING = 'ALERTS_PENDING';
export const ALERTS_SUCCESS = 'ALERTS_SUCCESS';
export const ALERTS_ERROR = 'ALERTS_ERROR';

export const PARKING_PENDING = 'PARKING_PENDING';
export const PARKING_SUCCESS = 'PARKING_SUCCESS';
export const PARKING_ERROR = 'PARKING_ERROR';

export const LIFTS_PENDING = 'LIFTS_PENDING';
export const LIFTS_SUCCESS = 'LIFTS_SUCCESS';
export const LIFTS_ERROR = 'LIFTS_ERROR';

export const TRAILS_PENDING = 'TRAILS_PENDING';
export const TRAILS_SUCCESS = 'TRAILS_SUCCESS';
export const TRAILS_ERROR = 'TRAILS_ERROR';

export const ROADS_PENDING = 'ROADS_PENDING';
export const ROADS_SUCCESS = 'ROADS_SUCCESS';
export const ROADS_ERROR = 'ROADS_ERROR';

export const LIVE_WEATHER_PENDING = 'LIVE_WEATHER_PENDING';
export const LIVE_WEATHER_SUCCESS = 'LIVE_WEATHER_SUCCESS';
export const LIVE_WEATHER_ERROR = 'LIVE_WEATHER_ERROR';

export const PLACES_PENDING = 'PLACES_PENDING';
export const PLACES_SUCCESS = 'PLACES_SUCCESS';
export const PLACES_ERROR = 'PLACES_ERROR';

export const TERRAINS_PENDING = 'TERRAINS_PENDING';
export const TERRAINS_SUCCESS = 'TERRAINS_SUCCESS';
export const TERRAINS_ERROR = 'TERRAINS_ERROR';
