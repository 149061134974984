/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DorMeasurementFieldNames, LiftTrailStatus } from '@powdr/constants';
import { getLifts } from '@powdr/stores';
import { filterLiftTrailItemsBySeason } from '@powdr/utils';

export const useLiftsMeasurements = (ignoreLiftTypes, season) => {
  const dispatch = useDispatch();
  const useLiftsData = useSelector((state) => state.lifts);
  const { data } = useLiftsData;
  const [liftsData, setLiftsData] = useState(null);

  useEffect(() => {
    dispatch(getLifts());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setLiftsData({
        [DorMeasurementFieldNames.OPEN]: filterLiftTrailItemsBySeason(
          data
            .filter((l) => !ignoreLiftTypes.includes(l.type))
            .filter((l) => (l.status === LiftTrailStatus.OPEN || l.status === LiftTrailStatus.EXPECTED)),
          season,
        ).length,
        [DorMeasurementFieldNames.TOTAL]: filterLiftTrailItemsBySeason(
          data
            .filter((l) => !ignoreLiftTypes.includes(l.type)),
          season,
        ).length,
      });
    }
  }, [ignoreLiftTypes, data, season]);

  return liftsData;
};
