import { FetchStatus, SnowReportUnitsDisplay } from '@powdr/constants';

import { SNOW_REPORTS_PENDING, SNOW_REPORTS_SUCCESS, SNOW_REPORTS_ERROR } from '../types';

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

const reducer = (data) => data.map((r) => {
  const {
    name,
    report,
    date,
    location,
    base_depth: baseDepth,
    overnight_amount: overnightAmount,
    computed,
    author = {},
    unit_of_measurement: units = SnowReportUnitsDisplay.INCHES,
  } = r;

  return {
    name,
    report,
    date,
    measurements: {
      base_depth: baseDepth,
      overnight_amount: overnightAmount,
      ...computed,
    },
    location: location.name,
    units: SnowReportUnitsDisplay[units],
    author: (!author?.name || !author?.image) ? null : author,
  };
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SNOW_REPORTS_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case SNOW_REPORTS_SUCCESS:
      return {
        ...state,
        data: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case SNOW_REPORTS_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
