import styled, { css } from 'styled-components';

import { Icon, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontSize,
  GlobalLayoutValues,
  FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.SIDEBAR;

export const StyledSidebarItem = styled.div`
  position: relative;
  padding: 0 10px;
  width: 100%;

  ${Breakpoints.desktopOnly(css`
    transition:
      margin-bottom ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease,
      height ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease;
  `)}

  ${Breakpoints.mobile(css`
    margin-bottom: 0;
    height: 100%;
  `)}
`;

export const SidebarItemInner = styled.div``;

export const SidebarItemLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.CONTENT, CPNT, Prop.BTN_TXT)};
    outline-offset: 0.2em;
  }
`;

export const SidebarIcon = styled(Icon)`
  ${Breakpoints.desktopOnly(css`
    height: ${({ $minimize }) => (($minimize) ? 27 : 35)}px;
    transition: height ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms ease;
  `)}

  ${Breakpoints.mobile(css`
    height: 27px;
  `)}

  svg {
    fill: ${() => componentColor(Prop.CONTENT, CPNT, Prop.BTN_TXT)};
    color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.BTN_TXT)};
  }
`;

export const SidebarText = styled.div`
  height: 100%;
  padding-top: 10px;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG20, undefined, undefined, CPNT, 'label')};
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.BTN_TXT)};
  line-height: 1.1;
  width: 80px;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;
