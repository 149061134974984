/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Icon, Link, WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints, Layouts,
  FontSize,
  FontFamily,
} from '@powdr/constants';
import {
  themeUtil, clamp, getFontStyles,
} from '@powdr/utils';

const CPNT = Components.DOR_SNOW_REPORT;

export const StyledDorSnowReport = styled.div`
  display: grid;
  margin-bottom: 25px;

  ${Breakpoints.desktopOnly(css`
    ${({ $layout, $isParentNavFeed, $isInColumn }) => ($isParentNavFeed && !$isInColumn
    ? 'grid-template-columns: 1fr 35%; gap: 15px;'
    : ($layout === Layouts.STACKED)
      ? 'grid-template-columns: repeat(1, 1fr);'
      : 'grid-template-columns: repeat(2, 1fr);')};
  `)}

  ${Breakpoints.small(css`
    ${({ $isParentNavFeed }) => ($isParentNavFeed && 'grid-template-columns: none;')};
  `)}
`;

export const DataSection = styled.div``;

export const StaticContent = styled(WysiwygStyleWrapper)``;

export const ReportSection = styled(WysiwygStyleWrapper)``;

export const ReportAuthor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  gap: 15px;
  margin-top: 50px;
`;

export const ReportAuthorImage = styled.img`
  width: 115px;
  height: 115px;
  aspect-ratio: 1/1;
  border-radius: 50%;
`;

export const ReportAuthorName = styled.div`
  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, Components.WYSIWYG, 'body')};

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 0.9, undefined, Components.WYSIWYG, 'body')};
  `)}

  ${Breakpoints.larger(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined, Components.WYSIWYG, 'body')};
  `)}
`;

export const Timestamp = styled.small`
  display: block;
  line-height: 1;
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  margin-bottom: 15px;
`;

export const ReportDataItems = styled.ul`
  display: grid;
  margin-bottom: 35px;
  gap: 5px;
  ${Breakpoints.desktopOnly(css`
    ${({ $layout, $numItems, $isParentNavFeed }) => (($isParentNavFeed)
    ? 'display: flex; flex-wrap: wrap;'
    : ($layout === Layouts.STACKED)
      ? `grid-template-columns: repeat(${$numItems}, 1fr);`
      : 'grid-template-columns: repeat(2, 1fr); gap: 75px;')};
  `)};

  ${Breakpoints.small(css`
    margin-bottom: 25px;
    grid-template-columns: ${({ $isParentNavFeed }) => (($isParentNavFeed) ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)')};
    row-gap: 50px;
    column-gap: 10px;
  `)}
`;

export const ReportDataItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  ${({ $isParentNavFeed }) => ($isParentNavFeed) && 'flex-grow: 1;'};

  h3 {

  }

  p {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, '64px', undefined, undefined)};
    line-height: 1;
  }



  ${Breakpoints.mobile(css`
    h3 {
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
      margin-bottom: 10px;
    }

    p {
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined)};
    }
  `)}
`;

export const ItemHeader = styled.h3`
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
  margin-bottom: 15px;

  ${Breakpoints.mobile(css`
    h3 {
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
      margin-bottom: 10px;
    }
  `)}
`;

export const ItemText = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ItemValue = styled.p`
  ${Breakpoints.mobile(css`
    h3 {
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
      margin-bottom: 10px;
    }

    p {
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined)};
    }
  `)}
`;

export const ItemUnit = styled.span`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined)};
`;

export const ItemIcon = styled(Icon)`
  width: 35px;
  height: 35px;

  svg {
    fill: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
    color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  }

  ${Breakpoints.mobile(css`
    width: 28px;
    height: 28px;
  `)}
`;

export const ItemAlignmentWrapper = styled.div``;

export const ReportContentWrapper = styled.div`
  ${({ $isParentNavFeed }) => ($isParentNavFeed) && clamp(8)};
  margin-bottom: 15px;
`;

export const NavFeedLink = styled(Link)``;
