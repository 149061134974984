import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { TvPagination } from '@powdr/components';

import {
  StyledTvWebcams,
  WebcamContainer,
  WebcamLabel,
} from './styles';

export const TvWebcams = ({
  displayTime,
  relationships,
}) => {
  const { webcams } = relationships;
  const itemsPerPage = 2;
  const numPages = Math.ceil(relationships.webcams.length / itemsPerPage);
  const perPageDisplayTime = (displayTime * 1000) / numPages;
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItems, setCurrentItems] = useState();
  const webcamsRotationRef = useRef();

  useEffect(
    () => {
      webcamsRotationRef.current = setTimeout(() => {
        setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1);
      }, perPageDisplayTime);

      return () => {
        clearTimeout(webcamsRotationRef.current);
      };
    },
    [perPageDisplayTime, currentPage, numPages],
  );

  useEffect(() => {
    setCurrentItems(relationships.webcams.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage,
    ));
  }, [currentPage, itemsPerPage, relationships]);

  if (!currentItems) return null;

  return (
    <StyledTvWebcams $numCams={webcams?.length}>
      {webcams.map((cam) => (
        <WebcamContainer key={cam.id}>
          <img src={cam.webcamImageSrc} alt={cam.webcamLabel || ''} />
          <WebcamLabel>{cam.webcamLabel}</WebcamLabel>
        </WebcamContainer>
      ))}
      <TvPagination numItems={numPages} activeItem={currentPage} />
    </StyledTvWebcams>
  );
};

TvWebcams.propTypes = {
  displayTime: PropTypes.number,
  relationships: PropTypes.shape({
    webcams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

TvWebcams.defaultProps = {
  displayTime: null,
};
