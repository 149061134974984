import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, StaticProfiles, FontFamily, FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvPagination = styled.div`
  position: fixed;
  display: flex;
  gap: 10px;
  height: 25px;
  justify-content: center;
  align-items: center;
  bottom: 100px;
`;

export const Dot = styled.div`
  border: 3px solid ${() => themeUtil(Prop.SIDE_NAV_ITEM_BG, null, StaticProfiles.TV)};
  background-color: ${({ $active }) => (($active) ? themeUtil(Prop.SIDE_NAV_ITEM_BG, null, StaticProfiles.TV) : 'unset')};
  height: 25px;
  width: 25px;
  border-radius: 50%;
  transition: all 0.25s ease-in;
`;
