import styled from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, FontFamily, StaticProfiles,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvAlerts = styled.div`
  width: 100%;
  height: 100%;
`;

export const TimestampIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  svg {
    color: ${() => themeUtil(Prop.WARNING, null, StaticProfiles.ALERTS)};
    fill: ${() => themeUtil(Prop.WARNING, null, StaticProfiles.ALERTS)};
  }
`;

export const AlertIcon = styled(Icon)`
  height: 200px;
  width: 200px;
`;

export const Timestamp = styled.div`
  display: flex;
  flex-direction: column;
  color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
`;

export const TimestampDate = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '65px')};
`;

export const TimestampTime = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '65px')};
`;

export const Message = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '100px')};
  margin-top: 75px;
  margin-right: 10%;

  * {
    color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
  }
`;
