import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, FontFamily, FontSize,
  StaticProfiles,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvWebcams = styled.div`
  display: grid;
  grid-template-columns: ${({ $numCams }) => `repeat(${($numCams > 2) ? 2 : $numCams}, 1fr)`};
  gap: 35px;
  width: 100%;

  ${Breakpoints.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

export const WebcamContainer = styled.div`
  display: block;

  img {
    aspect-ratio: 16/9;
    height: auto;
    width: 100%;
    object-fit: fill;
  }
`;

export const WebcamLabel = styled.p`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG20, undefined, undefined)};
  margin-top: 15px;
  line-height: 1.1;
`;
