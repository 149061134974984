import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Symbols } from '@powdr/constants';
import { getWeather } from '@powdr/stores';
import { capitalizeFirstWord, getIcon } from '@powdr/utils';

import {
  BottomSectionWrapper,
  DayText, Description, ForecastIcon,
  StyledTvForecast, TempText, TopSectionWrapper, TvForecastItem,
} from './styles';

export const TvForecast = ({ className }) => {
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const { data: weather } = useWeatherData;
  const [forecast, setForecast] = useState([]);

  useEffect(() => {
    dispatch(getWeather());
  }, [dispatch]);

  useEffect(() => {
    if (weather?.forecast && Array.isArray(weather?.forecast)) {
      setForecast(weather.forecast.slice(0, 3));
    }
  }, [weather, setForecast]);

  return (
    <StyledTvForecast className={className}>
      {forecast.map((f) => (
        <TvForecastItem>
          <TopSectionWrapper>
            <ForecastIcon name={getIcon(f.weather_icon)} height={275} width="fit-content" />
            <TempText>{`${f.temperature_high}${Symbols.DEGREE}/${f.temperature_low}${Symbols.DEGREE}`}</TempText>
          </TopSectionWrapper>
          <BottomSectionWrapper>
            <DayText>{`${f.day}`}</DayText>
            <Description $longDesc={f?.description_long && f?.description_long?.length > 0}>
              {(f?.description_long && f?.description_long?.length > 0)
                ? f.description_long
                : capitalizeFirstWord(f.description_short)}
            </Description>
          </BottomSectionWrapper>
        </TvForecastItem>

      ))}
    </StyledTvForecast>
  );
};

TvForecast.propTypes = {
  className: PropTypes.string,
};

TvForecast.defaultProps = {
  className: '',
};
