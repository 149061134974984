import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Icon } from '@powdr/components';
import { GlobalLayoutValues } from '@powdr/constants';
import { componentFactory } from '@powdr/utils';

import {
  StyledSidebarTray,
  SidebarTrayInner,
  ComponentWrapper,
  TrayDecoration,
  CloseButtonMobile,
  CloseButtonDesktop,
  SidebarTrayAnimationWrapper,
} from './styles';

export const SidebarTray = ({
  className,
  component,
  isOpen,
  positionalData,
  itemIndex,
  handleDrawer,
}) => {
  const [trayColorOverride, setTrayColorOverride] = useState();

  return (
    <StyledSidebarTray
      className={className}
      $isOpen={isOpen}
      $offsetWidth={positionalData?.offsetWidth || 0}
      $height={positionalData?.height || 0}
      $sidebarHeight={positionalData?.sidebarHeight || 0}
      $component={component}
      $trayColorOverride={trayColorOverride}
      ref={(node) => node && (!isOpen
        ? node.setAttribute('inert', '')
        : node.removeAttribute('inert')
      )}
    >
      <SidebarTrayAnimationWrapper
        className={className}
        $isOpen={isOpen}
        $offsetWidth={positionalData?.offsetWidth || 0}
        $height={positionalData?.height || 0}
        $sidebarHeight={positionalData?.sidebarHeight || 0}
        $component={component}
      >
        <CloseButtonMobile
          onClick={() => handleDrawer(null)}
          $component={component}
          $trayColorOverride={trayColorOverride}
        >
          <Icon name="ui-close-circle" height={40} width={40} />
        </CloseButtonMobile>
        <SidebarTrayInner
          $isOpen={isOpen}
          $component={component}
          $trayColorOverride={trayColorOverride}
        >
          <CloseButtonDesktop
            onClick={() => handleDrawer(null)}
            $component={component}
            $trayColorOverride={trayColorOverride}
          >
            <Icon name="ui-close-circle" height={40} width={40} />
          </CloseButtonDesktop>
          {(component) && (
          <ComponentWrapper>
            {componentFactory([component], undefined, undefined, {
              height: positionalData.height + (GlobalLayoutValues.DESKTOP.SIDEBAR.PADDING * 2),
              minHeight: positionalData.sidebarHeight,
              handleDrawer,
              itemIndex,
              handleTrayColorOverride: setTrayColorOverride,
            })}
          </ComponentWrapper>
          )}
          <TrayDecoration $trayColorOverride={trayColorOverride} $component={component} />
        </SidebarTrayInner>
      </SidebarTrayAnimationWrapper>
    </StyledSidebarTray>
  );
};

SidebarTray.propTypes = {
  className: PropTypes.string,
  component: PropTypes.shape({
    typeName: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  positionalData: PropTypes.shape({
    sidebarHeight: PropTypes.number,
    offsetWidth: PropTypes.number,
    height: PropTypes.number,
  }),
  itemIndex: PropTypes.number.isRequired,
  handleDrawer: PropTypes.func.isRequired,
};

SidebarTray.defaultProps = {
  className: '',
  positionalData: {
    offsetWidth: 0,
    height: 0,
  },
};
