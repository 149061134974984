import ReCAPTCHA from 'react-google-recaptcha';
import styled, { css } from 'styled-components';

import {
  Components, Breakpoints, ThemeProps as Prop, StaticProfiles,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.EMAIL_SIGNUP;

export const EmailSignupContainer = styled.div`
  display: flex;
  min-height: 250px;
  background-color: ${() => themeUtil(Prop.LIGHTEST, CPNT, StaticProfiles.SHADES)};
`;

export const SocialIconListWrapper = styled.div`
  display: flex;

  ${Breakpoints.desktopOnly(css`
    margin-right: 10px;
  `)}
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 65px;

  ${Breakpoints.mobile(css`
    width: 100%;
    text-align: center;
    margin: 25px 0;

    form {
      margin: 0;
    }
  `)}
`;

export const SignupForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
    white-space: normal;
  `)}
`;

export const CaptchaAlignmentWrapper = styled.div`
`;

export const FormLabel = styled.label`
  color: ${() => themeUtil(Prop.DARKEST, CPNT, StaticProfiles.SHADES)};
  line-height: 40px;
  text-align: center;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};

  ${Breakpoints.desktopOnly(css`
    margin-right: 25px;
  `)}
`;

export const LabelInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${Breakpoints.mobile(css`
    flex-direction: column;
    margin-bottom: 25px;
  `)}
`;

export const InputSubmitWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

export const EmailInputField = styled.input`
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined)};
  height: 40px;
  width: 270px;

  ${Breakpoints.mobile(css`
    width: 200px;
  `)}
`;

export const SubmitButton = styled.input`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG50, undefined, undefined)};
  padding: 0 20px;
  border: none;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
  }

  &:disabled {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
    cursor: not-allowed;
  }
`;

export const StyledReCAPTCHA = styled(ReCAPTCHA)`
  margin-top: 15px;
`;

export const RecaptchaContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;
