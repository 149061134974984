/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  FooterNav, GlobalFooter, Partners, SocialIconList, Link,
} from '@powdr/components';
import { AppContext } from '@powdr/context';
import { FooterLinkType, PartnerType, GlobalFooterType } from '@powdr/model';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledFooter, FooterCopyright, MainFooterContainer } from './styles';

export const Footer = ({
  id,
  partners,
  copyright,
  footerLinks,
}) => {
  const {
    property, siteName, socialIcons, hidePropertyList,
  } = useStaticData();
  const d = new Date();

  const { svgs } = useContext(AppContext);

  return (
    <StyledFooter
      id={id}
      /* ref={(node) => node && (isDrawerOpen
        ? node.setAttribute('inert', '')
        : node.removeAttribute('inert')
      )} */
    >
      <MainFooterContainer
        $property={property}
      >
        <div className="footer-row">
          <div className="footer-column left footer-links">
            <h3>More About Us</h3>
            <FooterNav footerLinks={footerLinks} />
          </div>
          {partners.length > 0 && (
          <div className="footer-column right footer-partners">
            <h3>Our Partners</h3>
            <Partners partners={partners} />
          </div>
          )}
          {(partners.length === 0 && !hidePropertyList) && (
          <div className="footer-column right footer-brands">
            <h3>POWDR'S Adventure Lifestyle Brands</h3>
            <GlobalFooter />
          </div>
          )}
        </div>

        <div className="footer-row">
          <div className="footer-column left footer-social">
            {socialIcons.map((iconSet) => <SocialIconList key={iconSet.id} {...iconSet} />)}
          </div>

          {(partners.length > 0 && !hidePropertyList) && (
            <div className="footer-column right footer-brands">
              <h3>POWDR'S Adventure Lifestyle Brands</h3>
              <GlobalFooter />
            </div>
          )}
        </div>

        <div className="footer-row">
          <div className="footer-column left">
            <div className="footer-logo">
              <Link href="/" ariaLabel="Back to Homepage">
                <svgs.SiteLogoDesktopFooter />
              </Link>
            </div>
          </div>
        </div>
      </MainFooterContainer>

      {/* TODO: needs access to footer level color profile */}
      <FooterCopyright>
        {copyright ? (
          <p>{copyright}</p>
        ) : (
          <p>{`© ${format(d, 'yyyy')} ${siteName}`}</p>
        )}
      </FooterCopyright>
    </StyledFooter>
  );
};

Footer.propTypes = {
  id: PropTypes.string,
  partners: PropTypes.arrayOf(PartnerType),
  copyright: PropTypes.string,
  footerLinks: PropTypes.arrayOf(FooterLinkType),
  globalFooter: PropTypes.arrayOf(GlobalFooterType),
};

Footer.defaultProps = {
  id: '',
  partners: [],
  copyright: null,
  footerLinks: [],
  globalFooter: [],
};
