import React, { useContext } from 'react';

import {
  AbsolutePositions, AlignmentShorthand, Components, VerticalPosition,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { colorProfileByIndex, componentFactory } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledDorDrawer,
  DorDrawerBody,
  DorComponentGrid,
  SectionWrapper,
  SectionTitle,
  SectionLink,
  DorPromotionsList,
  PromotionItem,
  Promotion,
  PromoSection,
  PromoTitle,
  PromoDesc,
  PromoButton,
} from './styles';

export const DorDrawer = () => {
  const {
    setDrawerOpen,
    isDorDrawerOpen,
    setDorDrawerOpen,
  } = useContext(AppContext);
  const {
    dorDrawer,
  } = useStaticData();
  const promotionsColorProfile = colorProfileByIndex(dorDrawer?.promotionList?.colorProfile || 0);

  return (
    <StyledDorDrawer
      componentTheme={Components.DOR_DRAWER}
      isOpen={isDorDrawerOpen}
      slideInFrom={AbsolutePositions.TOP}
      isShowLogoHeader={false}
      closeButtonPosition={AlignmentShorthand.TOP_LEFT}
      closeDrawerFunc={() => setDrawerOpen(false, setDorDrawerOpen)}
      closeButtonLabel="Close DOR Information Drawer"
    >
      <DorDrawerBody>
        <DorComponentGrid $promoPlacement={dorDrawer.promotionsPlacement}>
          {/* Promotions Header */}
          {(dorDrawer?.promotionList) && (
            <DorPromotionsList className="promotions" $numItems={dorDrawer.promotionList?.relationships?.promotion?.length}>
              {dorDrawer?.promotionList?.relationships?.promotion?.map((p) => (
                <PromotionItem>
                  <Promotion
                    href={p.url}
                    target={p.target}
                    onClick={() => setDrawerOpen(false, setDorDrawerOpen)}
                    ariaLabel="Click to go be taken to additional promotion information"
                    $colorProfile={promotionsColorProfile}
                  >
                    <PromoSection>
                      <PromoTitle $colorProfile={promotionsColorProfile}>{p.title}</PromoTitle>
                    </PromoSection>
                    <PromoSection>
                      <PromoDesc $colorProfile={promotionsColorProfile}>{p.description}</PromoDesc>
                    </PromoSection>
                    <PromoSection $vertAlign={VerticalPosition.MIDDLE}>
                      <PromoButton
                        className="promo-button"
                        $colorProfile={promotionsColorProfile}
                        ref={(node) => node && node.setAttribute('inert', '')}
                      >
                        {p.buttonText}
                      </PromoButton>
                    </PromoSection>
                  </Promotion>
                </PromotionItem>
              ))}
            </DorPromotionsList>
          )}
          {dorDrawer?.feedSections?.map((c, idx) => {
            const component = c?.relationships?.dorComponent || {};

            return (
              <SectionWrapper
                key={c.id}
                className={`component${idx + 1}`}
                $gridArea={`component${idx + 1}`}
              >
                <SectionTitle>{c.title}</SectionTitle>
                <SectionLink
                  href={c.link}
                  onClick={() => setDrawerOpen(false, setDorDrawerOpen)}
                >
                  {c.linkText}
                </SectionLink>
                {componentFactory([component], null, null, {
                  isParentNavFeed: true,
                  navFeedLink: {
                    href: c.link,
                    text: c.linkText,
                    onClick: () => setDrawerOpen(false, setDorDrawerOpen),
                  },
                })}
              </SectionWrapper>
            );
          })}
        </DorComponentGrid>
      </DorDrawerBody>
    </StyledDorDrawer>
  );
};

DorDrawer.propTypes = {};

DorDrawer.defaultProps = {
  className: '',
};
