import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AnimateHeight from 'react-animate-height';

import { AppContext } from '@powdr/context';

import { SidebarTray } from '../sidebar-tray';

import {
  StyledSidebarItem, SidebarItemLink, SidebarIcon, SidebarText,
} from './styles';

export const SidebarItem = ({
  className,
  itemIndex,
  label,
  icon,
  link,
  component,
  isMinimized,
  isTrayActive,
  handleTray,
  trayData,
}) => {
  const { isMobile } = useContext(AppContext);

  return (
    <StyledSidebarItem
      className={className}
      $minimize={isMinimized}
    >
      {/* Sidebar Link/Button */}
      <SidebarItemLink
        title={label}
        href={(!component) ? link : '#'}
        onClick={() => (component) && handleTray(itemIndex)}
        ariaLabel={label}
      >
        <SidebarIcon
          name={icon}
          $minimize={isMinimized}
        />
        <AnimateHeight
          className="sidebar-item-height-wrapper"
          height={(isMinimized && !isMobile) ? 1 : 'auto'}
          easing="ease-in-out"
          style={{ flexShrink: 0 }}
          contentClassName={(isMinimized && !isMobile) ? 'content-wrapper animate-text' : 'content-wrapper'}
        >
          <SidebarText
            $minimize={isMinimized}
          >
            {label}
          </SidebarText>
        </AnimateHeight>
      </SidebarItemLink>

      {/* Desktop Component Tray */}
      {(!isMobile && component) && (
        <SidebarTray
          itemIndex={itemIndex}
          handleDrawer={handleTray}
          isOpen={isTrayActive}
          positionalData={trayData}
          component={component}
        />
      )}
    </StyledSidebarItem>
  );
};

SidebarItem.propTypes = {
  className: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.string,
  component: PropTypes.shape({}),
  isMinimized: PropTypes.bool.isRequired,
  isTrayActive: PropTypes.bool.isRequired,
  handleTray: PropTypes.func.isRequired,
  trayData: PropTypes.shape({}).isRequired,
};

SidebarItem.defaultProps = {
  className: '',
  component: null,
  link: '',
};
