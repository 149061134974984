import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSnowReports } from '@powdr/stores';
import { getSnowfallMeasurementUnit, getSnowReportByLocationName } from '@powdr/utils';

export const useCurrentSnowfall = () => {
  const dispatch = useDispatch();

  const useSnowReportData = useSelector((state) => state.snowReports);
  const { data } = useSnowReportData;
  const [snowfallMeasurement, setSnowfallMeasurement] = useState({
    value: null,
    unit: getSnowfallMeasurementUnit(),
  });
  const snowReport = getSnowReportByLocationName(data);

  useEffect(() => {
    dispatch(getSnowReports());
  }, [dispatch]);

  useEffect(() => {
    if (snowReport?.measurements) {
      setSnowfallMeasurement({
        value: snowReport?.measurements['24_hour'],
        unit: getSnowfallMeasurementUnit(),
      });
    }
  }, [snowReport]);

  return snowfallMeasurement;
};
