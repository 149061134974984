import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import { GlobalLayoutValues } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useIsUserScrolling, useIsWrapperMinimized } from '@powdr/hooks';
import { useStaticData } from '@powdr/web/src/hooks';

import { SidebarItem, SidebarTray } from './components';
import {
  SidebarItems,
  SidebarItemWrapper,
  StyledSidebar,
} from './styles';

export const Sidebar = () => {
  const { isMobile } = useContext(AppContext);
  const { sidebar, property } = useStaticData();
  const { yPosition } = useIsUserScrolling();
  const isWrapperMinimized = useIsWrapperMinimized();
  const [minimize, setMinimize] = useState(false);
  const [isMinimizeDisabled, setMinimizeDisabled] = useState(false);
  const [activeTray, setActiveTray] = useState(null);
  const sidebarRef = useRef(null);
  const sidebarItemRef = useRef(null);
  const localStorageItem = `${property}-alert-state`;

  const changeLocalStorageState = (state) => {
    const currentLocal = JSON.parse(localStorage.getItem(localStorageItem));

    // only change local storage state if the current local storage state is true
    if (currentLocal.isDrawerOpen !== false) {
      localStorage.setItem(localStorageItem, JSON.stringify({
        ...currentLocal,
        isDrawerOpen: state,
      }));
    }
  };

  useEffect(() => {
    if (activeTray !== null) {
      setMinimizeDisabled(true);
    } else {
      setTimeout(() => {
        setMinimizeDisabled(false);
      }, GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION + 100);
    }
  }, [activeTray]);

  useEffect(() => {
    if (!isMinimizeDisabled) {
      setMinimize(isWrapperMinimized);
    }
  }, [isMinimizeDisabled, isWrapperMinimized]);

  const handleActiveTray = (idx) => {
    setActiveTray((idx === activeTray) ? null : idx);

    // change the local storage state if tray is closed by user
    if (idx === null || idx === activeTray) {
      changeLocalStorageState(false);
    }
  };

  const getTrayData = useCallback((idx) => {
    if (sidebarRef?.current && sidebarItemRef?.current) {
      return {
        sidebarHeight: sidebarRef.current.offsetHeight,
        offsetWidth: sidebarItemRef.current?.children?.[idx].offsetWidth,
        height: sidebarItemRef.current?.children?.[idx].offsetHeight,
      };
    }

    return {
      sidebarHeight: 0,
      offsetWidth: 0,
      height: 0,
    };
  }, []);

  if (!sidebar || sidebar?.length === 0) return null;

  return (
    <StyledSidebar
      ref={sidebarRef}
      $minimize={minimize}
      className={(minimize) && 'sidebar-animate'}
      onMouseEnter={() => (!isMobile && minimize) && setMinimize(false)}
      onMouseLeave={() => (!isMobile
        && yPosition !== 0
        && !isMinimizeDisabled)
        && setMinimize(true)}
    >
      {(isMobile) && sidebar?.map((item, idx) => (
        (item?.relationships?.trayComponent?.length !== 0) && (
          <SidebarTray
            itemIndex={idx}
            isOpen={activeTray === idx}
            handleDrawer={handleActiveTray}
            component={item?.relationships?.trayComponent}
            positionalData={getTrayData(idx)}
          />
        )))}
      <SidebarItems
        id="sidebar-menu" // do not change, needed for tracking
        ref={sidebarItemRef}
      >
        {sidebar?.map((item, idx) => (
          <SidebarItemWrapper>
            <SidebarItem
              itemIndex={idx}
              label={item.title}
              link={item.link}
              icon={item.icon}
              component={(item?.relationships?.trayComponent?.length !== 0)
                ? item.relationships?.trayComponent
                : null}
              isMinimized={minimize}
              isTrayActive={activeTray === idx}
              handleTray={handleActiveTray}
              trayData={getTrayData(idx)}
            />
          </SidebarItemWrapper>
        ))}
      </SidebarItems>
    </StyledSidebar>
  );
};

Sidebar.propTypes = {};

Sidebar.defaultProps = {};
