/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, {
  useRef, useEffect, useState, useContext, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params';

import {
  Loader, Icon, UiDrawer,
  HtmlContentLoader,
} from '@powdr/components';
import {
  ButtonText, ColorProfiles, FilterDefaultState, FilterType,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useIsInView } from '@powdr/hooks';
import { getViewAll } from '@powdr/stores/actions/drupal-action';
import {
  camalize, camalizeToTitle, getUniqueListBy,
  getArrayIntersection, aliasContentBlockDataFromViews,
  indexByColorProfile,
} from '@powdr/utils';

import { Filters } from './components';
import {
  Header,
  ItemsContainer,
  FilterTags,
  FilterTag,
  StyledLayoutMain,
  StyledGrid,
  StyledLayoutAside,
  StyledLayoutRow,
  StyledDrawer,
  StyledProductFinder,
  ClearFilterButton,
} from './styles';

const DefaultItemCountText = {
  NON_PLURAL: 'Product',
  PLURAL: 'Products',
};

const CommaArrayParam = {
  encode: (array) => encodeDelimitedArray(array, ','),
  decode: (array) => decodeDelimitedArray(array, ','),
};

export const ProductFinder = ({
  parentColorProfile,
  filterToggleColorProfile,
  filterDrawerColorProfile,
  filterDefaultState,
  title,
  blockType,
  columnGap,
  rowGap,
  columnGapMobile,
  rowGapMobile,
  isHideTags,
  itemCountText,
  itemCountTextPlural,
  columnOption,
  columnOptionMobile,
  filterType,
  entityView,
  isHorizontalFilterLayout,
  noResultsMessage,
  relationships,
}) => {
  const {
    toggleBodyScroll, isMobile,
    secondLevelNavHeight, fullHeaderHeight,
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const useProductData = useSelector((state) => state.drupal);
  const {
    isLoading: isProductLoading,
    drupal: productsData,
  } = useProductData;
  const [content] = relationships.content;
  const prettifyFilterName = (s) => camalize(s?.replace('field_', ''));
  const filterData = content.relationships.productGroups
    ?.filter((f) => f.name !== undefined)
    ?.map((p) => ({
      name: prettifyFilterName(p.name),
      label: p.label,
    }));
  const [urlQueryParams, setUrlQueryParams] = useQueryParams(Object.assign(
    ...filterData.map((grp) => ({ [grp.name]: CommaArrayParam })),
  ));
  const drawerStickyOffset = fullHeaderHeight + secondLevelNavHeight;
  const isHeaderScrolledThresholdPercentage = 0.01;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const productRef = useRef();
  const isInView = useIsInView(productRef, 0, isHeaderScrolledThresholdPercentage);
  const [isShowDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (isInView) {
      setShowDrawer(true);
    }
  }, [isInView]);

  useEffect(() => {
    dispatch(getViewAll(entityView.viewMachineName));
  }, [dispatch, entityView.viewMachineName]);

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target;
    const propId = e.target.dataset.groupId;

    setUrlQueryParams((prevState) => ({
      [propId]: checked
        ? [...prevState[propId] || [], value]
        : prevState[propId]?.filter((v) => v !== value) || [],
    }));
  };

  const handleTagClick = useCallback((propId, value) => {
    setUrlQueryParams((prevState) => ({
      [propId]: prevState[propId]?.filter((v) => v !== value) || [],
    }));
  }, [setUrlQueryParams]);

  const handleClearClick = () => {
    setUrlQueryParams(Object.keys(urlQueryParams)
      .reduce((a, b) => {
        a[b] = undefined;
        return a;
      }, {}));
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
    toggleBodyScroll(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    toggleBodyScroll(false);
  };

  const getIds = (prd, ids) => prd
    ?.filter((f) => f.tags
      ?.some((e) => ids?.includes(e)))
    ?.map((m) => m.id) || [];

  // get all products and clean up their properties
  const products = useMemo(
    () => productsData?.[entityView.viewMachineName]?.map((obj) => Object.assign(...[
      aliasContentBlockDataFromViews(obj),
      {
        tags: Object.entries(obj)
          ?.filter(([k]) => filterData.map((f) => f.name).includes(prettifyFilterName(k)))
          ?.map(([, v]) => v.split(', ')
            .filter((m) => m !== '')
            .map((m) => camalize(m)))
          .flat(),
      },
    ])) || [],

    [productsData, entityView.viewMachineName, filterData],
  );

  // Check all products from above to get all possible tags based
  // on fields passed to this component
  const tags = useMemo(
    () => products?.map((obj) => Object.entries(obj)
      ?.filter(([k, v]) => filterData.map((f) => f.name).includes(prettifyFilterName(k)) && v !== '')
      ?.map(([k, v]) => ({
        [prettifyFilterName(k)]: v.split(', ')
          .map((m) => ({
            label: m,
            id: camalize(m),
          })),
      }))).flat() || [],
    [products, filterData],
  );

  // using tags, setup the data for proper filtering based on filter names and all tags
  // that exist in products
  const filterGroups = useMemo(
    () => filterData
      ?.map((filter) => ({
        name: filter.name,
        label: filter.label,
        inputs: getUniqueListBy(tags
          .filter((f) => f[filter.name])
          .map((m) => m[filter.name]).flat()
          .map((m) => (
            {
              ...m,
            }
          )), 'id'),
      })) || [],
    [filterData, tags],
  );

  const filteredProducts = useMemo(() => {
    if (products.length === 0) return [];

    let productIDs = [];
    const queryIds = Object.values(urlQueryParams)
      ?.map((v) => v)
      ?.filter((f) => f !== undefined);

    // if no filters selected
    if (queryIds.flat().length === 0) {
      return products.map((p) => p.id);
    }

    if (queryIds.flat().length > 0 && filterType === FilterType.OR) {
      productIDs = getIds(products, queryIds.flat());
    }

    if (queryIds.length > 0 && (filterType === FilterType.AND_OR || !filterType)) {
      productIDs = queryIds
        ?.map((m) => getIds(products, m))
        ?.filter((f) => f.length > 0); // filter out empty arrays

      productIDs = [...productIDs].length > 1
        ? getArrayIntersection(...productIDs)
        : getIds(products, queryIds.flat());
    }

    return productIDs;
  }, [urlQueryParams, products, filterType]);

  const filterTags = useMemo(() => Object.entries(urlQueryParams)
    .filter((key, val) => val !== undefined)
    .map(([key, val]) => ((val?.length > 0) && (
      val?.map((v) => (
        <FilterTag key={v} colorProfile={parentColorProfile}>
          <button
            className="btn"
            type="button"
            aria-label={`Clear ${v} filter`}
            data-group-id={key}
            onClick={() => handleTagClick(key, v)}
            onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
          >
            {camalizeToTitle(v)}
            <Icon
              name="content-info-close"
              width="16"
              height="16"
              className="icon"
            />
          </button>
        </FilterTag>
      ))
    ))), [urlQueryParams, handleTagClick, parentColorProfile]);

  return (
    <StyledProductFinder colorProfile={parentColorProfile}>
      {isProductLoading ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="dor-sunny"
          size="30"
        />
      ) : (
        <StyledLayoutRow
          ref={productRef}
          $columnGap={columnGap}
          $horizontalFilter={isHorizontalFilterLayout}
        >
          {(isMobile && isShowDrawer) && (
            <StyledDrawer
              $drawerButtonColor={filterToggleColorProfile || ColorProfiles.BASE}
              $colorProfile={filterDrawerColorProfile || ColorProfiles.PRIMARY}
              top={`${drawerStickyOffset - 50}`}
            >
              <UiDrawer
                isDrawerOpen={isDrawerOpen}
                isDrawerEnabled
                handleDrawOpen={onDrawerOpen}
                handleCanvasClose={onDrawerClose}
                drawerOffsetTop={0}
                drawerWidth={310}
              >
                <Filters
                  filterToggleColorProfile={filterToggleColorProfile || ColorProfiles.BASE}
                  filterDrawerColorProfile={filterDrawerColorProfile || ColorProfiles.PRIMARY}
                  filters={filterGroups}
                  handleCheckboxClick={handleCheckboxClick}
                  urlQueryParams={urlQueryParams}
                  isMobile={isMobile}
                />
                <ClearFilterButton
                  aria-label="Clear all selected filters"
                  colorProfile={filterDrawerColorProfile || ColorProfiles.PRIMARY}
                  onClick={(e) => handleClearClick(e)}
                  onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
                >
                  {ButtonText.CLEAR_FILTERS}
                </ClearFilterButton>
              </UiDrawer>
            </StyledDrawer>
          )}
          {(!isMobile) && (
            <StyledLayoutAside
              $horizontalFilter={isHorizontalFilterLayout}
              $stickyPosition={drawerStickyOffset + 80}
            >
              <Filters
                filterToggleColorProfile={filterToggleColorProfile
                  || ColorProfiles.BASE}
                filterDrawerColorProfile={filterDrawerColorProfile
                  || ColorProfiles.PRIMARY}
                filters={filterGroups}
                filterDefaultState={(filterDefaultState === FilterDefaultState.OPEN)}
                handleCheckboxClick={handleCheckboxClick}
                urlQueryParams={urlQueryParams}
                isHorizontalFilterLayout={isHorizontalFilterLayout}
              />
            </StyledLayoutAside>
          )}
          <StyledLayoutMain>
            <ItemsContainer $padding={columnGap}>
              {title && (
                <Header $colorProfile={parentColorProfile}>
                  <h3>{title}</h3>
                  <p>
                    {filteredProducts.length}
                    {' '}
                    {(filteredProducts?.length === 1)
                      ? itemCountText || DefaultItemCountText.NON_PLURAL
                      : itemCountTextPlural || DefaultItemCountText.PLURAL}
                  </p>
                </Header>
              )}
              {(filterTags.filter((x) => x)?.length !== 0) && (
                <FilterTags>
                  {filterTags}
                </FilterTags>
              )}
              {(filteredProducts.length === 0) && <HtmlContentLoader html={noResultsMessage?.value || 'No results with given filters.'} allowRerender />}
              <StyledGrid
                colorProfile={indexByColorProfile(parentColorProfile)}
                parentColorProfile={parentColorProfile}
                columnOption={columnOption}
                columnOptionMobile={columnOptionMobile}
                blockType={blockType}
                columnGap={(columnGap === null) ? 1 : columnGap}
                rowGap={(rowGap === null) ? 10 : rowGap}
                columnGapMobile={(columnGapMobile === null) ? 1 : columnGapMobile}
                rowGapMobile={(rowGapMobile === null) ? 10 : rowGapMobile}
                isHideTags={isHideTags}
                relationships={{
                  contentBlocks: products
                    ?.filter((f) => (filteredProducts?.length > 0
                      ? filteredProducts.includes(f.id)
                      : false))
                    || [],
                }}
              />
            </ItemsContainer>
          </StyledLayoutMain>
        </StyledLayoutRow>
      )}
    </StyledProductFinder>
  );
};

ProductFinder.propTypes = {
  title: PropTypes.string,
  blockType: PropTypes.string,
  columnOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  columnOptionMobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
  columnGapMobile: PropTypes.number,
  rowGapMobile: PropTypes.number,
  isHideTags: PropTypes.bool,
  itemCountText: PropTypes.string,
  itemCountTextPlural: PropTypes.string,
  noResultsMessage: PropTypes.string,
  filterType: PropTypes.oneOf([
    FilterType.AND_OR,
    FilterType.OR,
    FilterType.AND,
  ]),
  entityView: PropTypes.shape({
    viewMachineName: PropTypes.string,
  }),
  isHorizontalFilterLayout: PropTypes.bool,
  relationships: PropTypes.shape(),
  filterToggleColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filterDrawerColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  filterDefaultState: PropTypes.oneOf(Object.values(FilterDefaultState)),
};

ProductFinder.defaultProps = {
  title: null,
  blockType: null,
  columnOption: null,
  columnOptionMobile: null,
  columnGap: 10,
  rowGap: 10,
  columnGapMobile: 10,
  rowGapMobile: 10,
  filterType: FilterType.AND_OR,
  isHideTags: false,
  itemCountText: DefaultItemCountText.NON_PLURAL,
  itemCountTextPlural: DefaultItemCountText.PLURAL,
  noResultsMessage: null,
  entityView: null,
  isHorizontalFilterLayout: false,
  relationships: null,
  parentColorProfile: null,
  filterToggleColorProfile: ColorProfiles.BASE,
  filterDrawerColorProfile: ColorProfiles.PRIMARY,
  filterDefaultState: FilterDefaultState.CLOSED,
};
