import PropTypes from 'prop-types';
import React, {
  useEffect, useRef, useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { WysiwygStyleWrapper, TvPagination } from '@powdr/components';
import { getViewAll } from '@powdr/stores';
import { aliasContentBlockDataFromViews, camalizeToTitle } from '@powdr/utils';

import {
  GridCaption,
  GridWrapper,
  StyledTvGrid,
} from './styles';
import { TvBasic } from './tv-block-types/tv-basic';

export const TvGrid = ({
  displayTime,
  view,
  columnOption,
  gridCaption,
}) => {
  const columns = columnOption || 2;
  const itemsPerPage = columns;
  const [numPages, setNumPages] = useState();
  const [perPageDisplayTime, setPerPageDisplayTime] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItems, setCurrentItems] = useState();
  const gridRotationRef = useRef();
  const dispatch = useDispatch();
  const { drupal } = useSelector((state) => state.drupal) || [];

  useEffect(() => {
    dispatch(getViewAll(view.viewMachineName));
  }, [dispatch, view.viewMachineName]);

  useEffect(
    () => {
      if (perPageDisplayTime && numPages) {
        gridRotationRef.current = setTimeout(() => {
          setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1);
        }, perPageDisplayTime);
      }

      return () => {
        clearTimeout(gridRotationRef.current);
      };
    },
    [perPageDisplayTime, currentPage, numPages],
  );

  useEffect(() => {
    // wait for drupal data, then set necessary states
    if (drupal?.[view.viewMachineName]) {
      const tempNumPages = Math.ceil(drupal[view.viewMachineName].length / itemsPerPage);
      setNumPages(tempNumPages);
      setPerPageDisplayTime((displayTime * 1000) / tempNumPages);
      setCurrentItems(
        drupal?.[view.viewMachineName]
          ?.map((cb) => ({
            ...aliasContentBlockDataFromViews(cb), id: `view id ${Math.random()}`,
          }))
          .slice(
            currentPage * itemsPerPage,
            (currentPage + 1) * itemsPerPage,
          ),
      );
    }
  }, [currentPage, itemsPerPage, drupal, displayTime, view]);

  if (!currentItems) return null;

  return (
    <StyledTvGrid>
      <GridWrapper $columns={columns}>
        {currentItems.map((cb, index) => {
          const { tags, viewImage, formattedContent } = cb;
          const image = { altText: '', src: viewImage?.default };
          const prettifyTags = (Array.isArray(cb.tags)) ? tags.map((s) => camalizeToTitle(s)).join(', ') : tags;

          return React.createElement(
            TvBasic,
            {
              ...cb,
              key: `id-${Math.random()}`,
              index,
              image,
              columnOption: columns,
              tags: prettifyTags,
              formattedContent: formattedContent?.value,
            },
          );
        })}
      </GridWrapper>
      {(gridCaption?.value) && (
        <GridCaption>
          <WysiwygStyleWrapper>{ReactHtmlParser(gridCaption.value)}</WysiwygStyleWrapper>
        </GridCaption>
      )}
      <TvPagination numItems={numPages} activeItem={currentPage} />
    </StyledTvGrid>
  );
};

TvGrid.propTypes = {
  displayTime: PropTypes.number,
  columnOption: PropTypes.number,
  gridCaption: PropTypes.shape({
    value: PropTypes.string,
  }),
  view: PropTypes.shape({ viewMachineName: PropTypes.string }).isRequired,
};

TvGrid.defaultProps = {
  displayTime: null,
  columnOption: 3,
  gridCaption: {
    value: null,
  },
};
