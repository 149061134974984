import styled, { css } from 'styled-components';

import { Breakpoints } from '@powdr/constants';
import { clamp } from '@powdr/utils';

export const StyledDorMiniAlert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: ${({ $height }) => (($height) ? `${$height}px` : '100%')};

  ${Breakpoints.mobile(css`
    text-align: center;
    height: 100%;
    padding: 15px 0;
  `)}
`;

export const Title = styled.h1`
  margin-bottom: 10px;
  width: 100%;
`;

export const Message = styled.div`
  width: 100%;

  p {
    ${() => clamp(3)};
    line-height: normal;
    margin-bottom: 5px;
  }
`;
