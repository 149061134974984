import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { isBuildTime } from '@powdr/utils';

export const SkipperHospitalityWidget = ({
  hotelID,
  buttonIDs,
}) => {
  if (isBuildTime()) return null;
  const skipperTargetId = 'skipper-target';
  const skipperScriptId = 'skipper-init-script';
  const [targetAdded, setTargetAdded] = useState(false);

  // add the skipper target element
  useEffect(() => {
    const skipperTarget = document.createElement('div');
    skipperTarget.setAttribute('id', skipperTargetId);

    if (document?.body) {
      document.body.appendChild(skipperTarget);
      setTargetAdded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (targetAdded) {
      const skipperScript = document.createElement('script');
      skipperScript.setAttribute('id', skipperScriptId);
      skipperScript.textContent = `
        (function checkSkipperInit() {
          if (window.initSkipper !== undefined) {
            window.initSkipper({
              target: "skipper-target",
              targetIds: [${buttonIDs?.map((s) => `"${s}"`)?.join(', ')}],
              targetClasses: [],
              hotelId: "${hotelID}",
              style: {
                primaryBackgroundColor: "#E1F1EF",
                accentColor: "#F05136",
                primaryColor: "#2F6165",
                primaryFontColor: "#2F6165",
                secondaryFontColor: "#6BD9DE",
                primaryBorderColor: "#2F6165",
                accentFontColor: "#FFFFFF",
                alertBackgroundColor: "#7e7e7e",
                successColor: "#22c55e",
                errorColor: "#b91c1c",
                errorBackgroundColor: "#ef5151",
                warningColor: "#facc15",
                inactiveOpacity: 0.5,
                disabledOpacity: 0.3,
                highlightOpacity: 0.1,
                stateOpacity: 0.4
              }
            });
          } else {
            // Variable is not defined, check again after a delay
            setTimeout(checkSkipperInit, 100); // Check every 100ms
          }
        })();
      `;

      if (document?.body) {
        document.body.appendChild(skipperScript);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetAdded]);

  return (
    hotelID && (
      <Helmet>
        <script src="https://widget.skipperhospitality.com/cash-sdk-load.umd.js" />
        <link
          rel="preload"
          href="https://widget.skipperhospitality.com/cash-sdk.umd.js"
          as="script"
          crossOrigin
        />
        <link
          rel="preload"
          href="https://widget.skipperhospitality.com/style.css"
          as="style"
          crossOrigin
        />
        <link
          rel="preload"
          href="https://widget.skipperhospitality.com/site-base.css"
          as="style"
          crossOrigin
        />
        <link rel="preconnect" href="https://cash-api.skipperhospitality.com" />
      </Helmet>
    )
  );
};

SkipperHospitalityWidget.propTypes = {
  hotelID: PropTypes.string.isRequired,
  buttonIDs: PropTypes.arrayOf(PropTypes.string),
};

SkipperHospitalityWidget.defaultProps = {
  buttonIDs: [],
};
